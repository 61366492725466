import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import { useParams } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';
import CompoundMarketplace from './CompoundMarketplace';
import { getTitle } from './utils/actions';

const debug = false;
const PgCompoundMarketplace = () => {
  const { view } = useParams();
  const { user, setUser } = useContext(UserContext);

  // make sure correct subApp selected for left side menus
  if (user.subApp !== 'compoundMarketplace') setUser((prev) => ({ ...prev, subApp: 'compoundMarketplace' }));
  debug && console.log('PgCompoundMarketplace | view:', view);

  return (<PageContainer title={getTitle(view)} showRepo={false}>
    <Container>
      <CompoundMarketplace view={view} />
    </Container>
  </PageContainer>);
};

export default PgCompoundMarketplace;

const Container = styled.div`
    font-size: 24px;
    color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  & ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
  }
  & ::-webkit-scrollbar-track {
      background: transparent;
  }
  & ::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.palette.accentPrimary};
      border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
      background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

