import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import {useQuery} from "@tanstack/react-query";
import { ListLoader } from '@as_core/elements/listStyles';
import useRequests from '../hooks/useRequests';
import { FlexRow } from '@as_core/elements/flexStyles';
import {UserContext} from "@stores/UserContext";
import RequestsTable from "./RequestsTable";

interface PropsI {
  requestType: string;
}

const debug = false;
const RequestsList = (props:PropsI) => {
  const {requestType} = props;
  const { user } = useContext(UserContext);
  const { getUserRequests, getRequestType } = useRequests();

  if (debug) console.log('user/requestsList | user: ', user);

  // load requests for the specific defined action
  const { isPending, isError, error, data: requests } = useQuery({
    queryKey: ['requests', 'user'],
    queryFn: () => getUserRequests(user.authId)
  });
  if (isError) console.log(error.message);

  if (debug) console.log('user/RequestsList', isPending, 'requests.length', requests === undefined ? 0 : requests.length);

  const filterRequests = requests === undefined ? undefined :
    requests.filter((r) => getRequestType(r).category === requestType)

  return (
    <>
      { !isPending ?
        <ListContainer>
          <RequestsTable
            requestType={requestType}
            requests={filterRequests}
          />
        </ListContainer>
      :
        <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
          <ListLoader />
        </FlexRow>
      }
    </>
  );
};

export default RequestsList;


const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
