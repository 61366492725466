import React from 'react';
import styled from 'styled-components/macro';
import { RequestT } from '../hooks/useRequests';
import usePartners from '@subApps/orders/hooks/usePartners';
import DownloadFileButton from '@as_core/controls/outputs/DownloadFileButton';
import useRequestStatus from '@subApps/orders/hooks/useRequestStatus';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const PlateMapInfo: string =
  'The plate map template includes the shipping instructions and ' +
  'the template spreadsheet to add the location information for each of the compounds sent in the plate. ' +
  'This template is specific to the types of assay/screens and the laboratory performing the screen, so ' +
  'Please confirm you have the correct template downloaded, and correctly filled out.';

interface PropsI {
  request: RequestT;
  isMuted?: boolean;
}

// TODO -- Store in S3 and the reference in database and allow update/edit admin tools in Partners Website
const PlateMapTemplate = (props: PropsI) => {
  const { request, isMuted = false } = props;
  const { getDownloadFileAndPath } = usePartners();
  const {file, path} = getDownloadFileAndPath(request.assigned_to)
  const queryClient = useQueryClient();
  const {updateRequestStatus} = useRequestStatus();

  const mutation = useMutation({
    mutationFn: () => updateRequestStatus(request, 'platemapTemplateDownloaded'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requestStatus', request.id] }).then();
    }
  });

  const handleAfterClick = () => {
    mutation.mutate();
  }

  return (
    <PlateMapTemplateContainer>
      <DownloadFileButton
        label={'Download Plate Map Template File'}
        info={PlateMapInfo}
        width={270}
        path={file}
        file={path}
        isMuted={isMuted}
        afterClick={handleAfterClick}
      />
    </PlateMapTemplateContainer>
  );
};
export default PlateMapTemplate;

const PlateMapTemplateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
