import React, { ReactNode } from 'react';
import Overview from '../components/Overview';
import Search from '../components/Search';
import Orders from '../components/Orders';
import PastOrders from '../components/PastOrders';

export const getTitle = (
  action: string
): string => {
  switch(action) {
    case 'overview':
      return 'Compound Marketplace Overview';
    case 'search':
      return 'Compound Marketplace Search';
    case 'orders':
      return 'Compound Marketplace Active Orders';
    case 'past':
      return 'Compound Marketplace Past Orders';
  }
  return 'Default Title';
}

export const getComponent = (
  view: string,
): ReactNode => {
  switch(view) {
    case 'overview':
      return <Overview />
    case 'search':
      return <Search />
    case 'orders':
      return <Orders />
    case 'past':
      return <PastOrders />
  }
  return <div>Unknown Action {view}</div>
}