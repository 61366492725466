import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import DashboardIcon from '@components/icons/dashboard.icon';
import SearchIcon from '@components/icons/search.icon';
import OrdersActiveIcon from '@components/icons/orders.active';
import OrdersPastIcon from '@components/icons/orders.past';

export const chosmosMenuItems: MenuItemsT[] = [
  {
    id: 'compoundMarketplaceDashboard',
    name: 'Overview',
    pathname: '/compoundMarketplace/overview',
    icon: <DashboardIcon />
  },
  {
    id: 'compoundMarketplaceSearch',
    name: 'Search',
    pathname: '/compoundMarketplace/search',
    icon: <SearchIcon />
  },
  {
    id: 'compoundMarketplaceOrders',
    name: 'Orders',
    pathname: '/compoundMarketplace/orders',
    icon: <OrdersActiveIcon />
  },
  {
    id: 'compoundMarketplacePast',
    name: 'Past Orders',
    pathname: '/compoundMarketplace/past',
    icon: <OrdersPastIcon />
  },
];

const debug = false;

export const getCompoundMarketplaceLogo = () => {
  return ('/img/Chosmos_Logo_notext.svg')
}

export const getCompoundMarketplaceMenus = (subscription: string) => {
  if (debug) console.log('getMenuAccount | {subscription}:', subscription);
  return chosmosMenuItems;
}
