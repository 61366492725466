import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ProductCategoryT } from '@business/products/products.types';
import CardCarousel from '@as_core/elements/CardCarousel';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import { Tooltip } from '@mui/material';

type PropsT = {
  productCategories: ProductCategoryT[];
  activeCategory: string;
  onSelect?: (v: string) => void;
  debug?: boolean;
};

const InfoText =
  'Select the screen/assay category to see the available products in each category. ' +
  'Multiple types of products can be ordered together in a single shopping cart, but after payment, they ' +
  'will be split into separate requests to the labs that will perform the experiments and generate the results.';

const ProductCategorySelector = (props: PropsT) => {
  const { productCategories, activeCategory } = props;
  const [cards, setCards] = useState<ReactNode[]>([]);
  if (props.debug) console.log(props);

  useEffect(() => {
    const productCards = productCategories.map((c, index) => (
      <>
        {activeCategory === c.categoryCode ? (
          <CategorySelector key={`category_selector_${index}`} active={true}>
            <Icon active={activeCategory === c.categoryCode}>
              {c?.icon ? c.icon : null}
            </Icon>
            <ProductTitle>{c.categoryName}</ProductTitle>
          </CategorySelector>
        ) : (
          <Tooltip title={c.categoryName} placement='top' arrow>
            <CategorySelector
              key={`category_selector_${index}`}
              active={activeCategory === c.categoryCode}
              onClick={() => props.onSelect(c.categoryCode)}
            >
              <Icon active={activeCategory === c.categoryCode}>
                {c?.icon ? c.icon : null}
              </Icon>
            </CategorySelector>
          </Tooltip>
        )}
      </>
    ));
    setCards(productCards);
  }, [productCategories, activeCategory]);

  if (props.debug) console.log('cards', cards);

  return (
    <CategoryContainer>
      <HoverInfoText text={InfoText} />
      <CategoryTitle>
        Available Compound Screens
      </CategoryTitle>
      <CategorySelectorContainer>
        <CardCarousel cards={cards} />
      </CategorySelectorContainer>
    </CategoryContainer>
  );
};

export default ProductCategorySelector;

const CategoryContainer = styled.span`
  display: flex;
  width: 100%;
  margin: 5px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CategorySelectorContainer = styled.span`
  display: flex;
  width: max-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 2px;
  width: 100px;
  font-size: 16px;
`;

const CategorySelector = styled.span<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => (p.active ? 'default' : 'pointer')};
  width: ${(p) => (p.active ? 200 : 50)}px;
  color: ${(p) =>
    p.active ? p.theme.palette.textPrimary : p.theme.palette.textSecondary};
  border-radius: 15px;
  padding: 5px;
  background-color: ${(p) =>
    p.active
      ? p.theme.palette.backgroundSecondary
      : p.theme.palette.backgroundTertiary};
  border: ${(p) => (p.active ? 2 : 1)}px solid
    ${(p) =>
      p.active
        ? p.theme.palette.accentPrimary
        : p.theme.palette.accentSecondary};

  :hover {
    background-color: ${(p) =>
      p.active
        ? p.theme.palette.backgroundPrimary
        : p.theme.palette.backgroundQuaternary};
  }
`;

const Icon = styled.div<{ active: boolean }>`
  display: flex;
  padding-right: 5px;
  ${(p) => (p.active ? `` : `filter: grayscale(1.0)`)};
`;

const ProductTitle = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  padding-left: 5px;
`;
