import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ColumnsIcon = ({size = 24}:{size?: number}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  return (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={color} d="M21.4,2.2H2.6c-1.4,0-2.6,1.2-2.6,2.6v14.8c0,1.4,1.2,2.6,2.6,2.6h18.8c1.4,0,2.6-1.2,2.6-2.6V4.8c0-1.4-1.2-2.6-2.6-2.6ZM14.8,4.2v16h-5.5V4.2h5.5ZM2,19.6V4.8c0-.3.3-.6.6-.6h4.6v16H2.6c-.3,0-.6-.3-.6-.6ZM22,19.6c0,.3-.3.6-.6.6h-4.6V4.2h4.6c.3,0,.6.3.6.6v14.8Z" />
  </svg>
  )
}

export default ColumnsIcon;